"use client";

import React, { FC, useEffect, useState } from "react";
import {
  collection,
  orderBy,
  query,
  limit,
  startAfter,
  getDocs,
  DocumentData,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";

type Character = {
  id: string;
  name: string;
  nameJapanese: string;
  mangaTitle: string;
  imageBigURL: string;
  rank: number;
  chatContent: string;
};

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export const CharacterListPage: FC = () => {
  const { setSelectedRoom, setSelectRoomName } = useAppContext();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 50;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const isXs = useMediaQuery("(max-width:800px)");
  const isSm = useMediaQuery("(max-width:960px)");
  const isMd = useMediaQuery("(min-width:961px)");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotalCharacters = async () => {
      const snapshot = await getDocs(collection(db, "characters"));
      const totalCharacters = snapshot.size;
      setTotalPages(Math.ceil(totalCharacters / itemsPerPage));
    };
    fetchTotalCharacters();
  }, []);

  const fetchCharacters = async (page: number) => {
    const characterCollectionRef = collection(db, "characters");
    let q;

    if (page === 1) {
      q = query(characterCollectionRef, orderBy("rank"), limit(itemsPerPage));
    } else {
      q = query(characterCollectionRef, orderBy("rank"), startAfter(lastVisibleDoc), limit(itemsPerPage));
    }

    const snapshot = await getDocs(q);
    const newCharacters = snapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      nameJapanese: doc.data().nameJapanese,
      mangaTitle: doc.data().mangaTitle,
      imageBigURL: doc.data().imageBigURL,
      rank: doc.data().rank,
      chatContent: doc.data().chatContent || "No messages yet",
    }));

    setCharacters(newCharacters);
    setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
  };

  useEffect(() => {
    fetchCharacters(page);
  }, [page]);

  const selectRoom = (characterId: string, characterName: string) => {
    setSelectedRoom(characterId);
    setSelectRoomName(characterName);
    navigate(`/character/${characterId}`);
  };
  const extractDomain = (url: string): string => {
    try {
      const { hostname } = new URL(url);
      return hostname; // ドメイン部分を返す
    } catch (error) {
      console.error("Invalid URL:", url);
      return url; // URLが無効の場合はそのまま返す
    }
  };
  
  const filterManga = (mangaTitle: string) => {
    navigate(`/manga/${mangaTitle}`);
  };

  const handleOpenModal = (image: string) => {
    setModalImage(image);
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);

  return (
    <div className="bg-white h-full overflow-y-auto px-1 flex flex-col">
      {/* サービスの使い方説明とQRコード、スクリーンショットを表示 */}
      <Box sx={{ padding: 2, backgroundColor: "#f0f0f5", borderRadius: "8px", marginBottom: 3 }}>
  <Typography variant="h5" gutterBottom>
    サービスの使い方
  </Typography>
  <Typography variant="body1" gutterBottom>
    このサービスでは、漫画やゲームのキャラクタになりきったAIと、色々なシチュエーションでチャットが楽しめます。
    また、LINE公式プラットフォームでも展開しており、以下のQRコードからアクセスできます。
    LINE AI BOTでは「!キャラクタ名」を入力することで、自由にキャラと会話できます。
    </Typography>
    <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      gap: 2, // 各画像の間隔を調整
      marginTop: 2,
    }}
  >

  <img
    src="/images/M_972othaz_GW.png"
    alt="LINE QRコード"
    style={{
      width: "200px",
      height: "200px",
      cursor: "pointer",
      border: "2px solid black", // 黒のボーダーを追加
      borderRadius: "4px" // 角を少し丸くする
    }}
    onClick={() => handleOpenModal("/images/M_972othaz_GW.png")}
  />
</Box>

</Box>


      {/* モーダル */}
      {/* <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={style}>
          <img src={modalImage} alt="拡大画像" style={{ width: "100%", height: "auto" }} />
        </Box>
      </Modal> */}

      <ImageList
        sx={{
          width: "100%",
          height: "auto",
          gap: isXs ? 4 : 8, // スマホでは隙間を小さく
        }}
        cols={isXs ? 1 : isSm ? 2 : isMd ? 4 : 6} // 画面サイズに応じたカラム数
      >
        {characters.map((character) => (

          <ImageListItem
            key={character.id}
            sx={{
              border:
                character.rank === 1
                  ? "3px solid gold"
                  : character.rank === 2
                  ? "3px solid silver"
                  : character.rank === 3
                  ? "3px solid bronze"
                  : "1px solid #ccc",
              position: "relative",
              borderRadius: "8px",
              overflow: "hidden",
              backgroundColor: "#fff",
            }}
          >
            {/* ランク表示 */}
            <div
              style={{
                position: "absolute",
                top: 5,
                left: 5,
                backgroundColor:
                  character.rank === 1
                    ? "gold"
                    : character.rank === 2
                    ? "silver"
                    : character.rank === 3
                    ? "bronze"
                    : "#007bff",
                color: "white",
                borderRadius: "50%",
                width: 30,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                zIndex: 2,
              }}
            >
              {character.rank}
            </div>

            {/* キャラクター画像 */}
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/ai-line-bot-f35b5.appspot.com/o/images%2F${character.rank}.jpeg?alt=media&token=b0a63abb-2bfb-4f63-9975-8f6e4da3658f`}
              alt={character.name}
              loading="lazy"
              style={{
                cursor: "pointer",
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
              onClick={() => selectRoom(character.id, character.name)}
            />

            {/* オーバーレイ (画像下部に引用元) */}
            <div
              style={{
                position: "absolute",
                bottom: 0, // 画像の下部に固定
                left: 0,
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)", // 半透明の黒背景
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                padding: "5px 0",
                zIndex: 1,
              }}
            >
              <a
                href={character.imageBigURL}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "white", // 明るい青でリンクを表示
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                画像引用元: {extractDomain(character.imageBigURL)}
              </a>
            </div>

            {/* キャラクター名と漫画タイトル */}
            <ImageListItemBar
              title={
                <span
                  onClick={() => selectRoom(character.id, character.name)}
                  style={{
                    cursor: "pointer",
                    color: "#007bff",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {character.nameJapanese} ({character.name})
                </span>
              }
              subtitle={
                <span
                  onClick={() => filterManga(character.mangaTitle)}
                  style={{
                    cursor: "pointer",
                    color: "#ff4500",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {character.mangaTitle}
                </span>
              }
              position="below"
            />
          </ImageListItem>

          // <ImageListItem
          //   key={character.id}
          //   sx={{
          //     border:
          //       character.rank === 1 ? "3px solid gold" : character.rank === 2 ? "3px solid silver" : character.rank === 3 ? "3px solid bronze" : "1px solid #ccc",
          //     position: "relative",
          //   }}
          // >
          //   <div
          //     style={{
          //       position: "absolute",
          //       top: 5,
          //       left: 5,
          //       backgroundColor: character.rank === 1 ? "gold" : character.rank === 2 ? "silver" : character.rank === 3 ? "bronze" : "#007bff",
          //       color: "white",
          //       borderRadius: "50%",
          //       width: 30,
          //       height: 30,
          //       display: "flex",
          //       justifyContent: "center",
          //       alignItems: "center",
          //       fontWeight: "bold",
          //     }}
          //   >
          //     {character.rank}
          //   </div>

          //   {/* 引用元リンク (画像の下部) */}
          //   <a
          //     href={character.imageBigURL}
          //     target="_blank"
          //     rel="noopener noreferrer"
          //     style={{
          //       position: "absolute",
          //       bottom: 5, // 下部に固定
          //       left: "50%",
          //       transform: "translateX(-50%)",
          //       fontSize: "10px",
          //       color: "#007bff",
          //       textDecoration: "none",
          //       backgroundColor: "rgba(255, 255, 255, 0.8)", // 見やすいように背景を追加
          //       padding: "2px 5px",
          //       borderRadius: "5px",
          //     }}
          //   >
          //     画像引用元: {extractDomain(character.imageBigURL)}
          //   </a>
          //   <img
          //     src={`https://firebasestorage.googleapis.com/v0/b/ai-line-bot-f35b5.appspot.com/o/images%2F${character.rank}.jpeg?alt=media&token=b0a63abb-2bfb-4f63-9975-8f6e4da3658f`}
          //     alt={character.name}
          //     loading="lazy"
          //     style={{
          //       cursor: "pointer",
          //       width: "100%",
          //       height: isXs ? "150px" : "auto", // スマホでは高さを固定
          //       objectFit: "cover",
          //     }}
          //     onClick={() => selectRoom(character.id, character.name)}
          //   />

          //   <ImageListItemBar
          //     title={
          //       <span
          //         onClick={() => selectRoom(character.id, character.name)}
          //         style={{
          //           cursor: "pointer",
          //           color: "#007bff",
          //           fontWeight: "bold",
          //           fontSize: "12px",
          //         }}
          //       >
          //         {character.nameJapanese} ({character.name})
          //       </span>
          //     }
          //     subtitle={
          //       <span
          //         onClick={() => filterManga(character.mangaTitle)}
          //         style={{
          //           cursor: "pointer",
          //           color: "#ff4500",
          //           fontWeight: "bold",
          //           fontSize: "10px",
          //         }}
          //       >
          //         {character.mangaTitle}
          //       </span>
          //     }
          //     position="below"
          //   />
          // </ImageListItem>
        ))}
      </ImageList>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, value) => setPage(value)}
          variant="outlined"
          color="primary"
        />
      </Box>
    </div>
  );
};

export default CharacterListPage;
