import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { db } from "../config/firebase";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useAppContext } from "../context/AppContext";
import OpenAI from "openai";
import { Box, Grid, TextField, IconButton, Typography, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

type Message = {
  text: string;
  sender: string;
  createdAt: Timestamp;
};

type LocationState = {
  relationship: string;
  characterName: string;
  characterJapaneseName: string;
  mangaTitle: string;
};

export const Chat: FC = () => {
  console.log("Chat component loaded");

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const location = useLocation();
  const { relationship, characterName, characterJapaneseName, mangaTitle } =
    location.state as LocationState; // CharacterPage から渡されるデータを取得

  const { userId } = useAppContext();
  const { roomId } = useParams<{ roomId: string }>();
  const [inputMessage, setInputMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [characterProfile, setCharacterProfile] = useState<string>("");
  const scrollDiv = useRef<HTMLDivElement>(null);
  const endDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCharacterProfile = async () => {
      try {
        const profilePrompt = `
          キャラクター情報:
          - 名前: ${characterJapaneseName} (${characterName})
          - 漫画タイトル: ${mangaTitle}
          - 関係性: ${relationship}
          以下の形式でキャラクターに合ったプロンプトを作成してください:
          - 口調
          - 態度
          - 会話スタイル
        `;

        console.log("Generated Profile Prompt:");
        console.log(profilePrompt); // プロンプトをログに出力

        const response = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [
            { role: "system", content: "指定されたキャラクター情報を提供してください。" },
            { role: "user", content: profilePrompt },
          ],
        });

        const profileContent = response.choices[0].message?.content || "";
        setCharacterProfile(profileContent);

        console.log("Character profile loaded:");
        console.log(profileContent); // プロフィールをログに出力
      } catch (error) {
        console.error("Error fetching character profile:", error);
      }
    };

    if (roomId) {
      fetchCharacterProfile();
    }
  }, [roomId, relationship, characterName, characterJapaneseName, mangaTitle]);

  useEffect(() => {
    if (roomId && userId) {
      const fetchMessages = async () => {
        try {
          const roomDocRef = doc(db, "rooms", roomId);
          const roomDocSnap = await getDoc(roomDocRef);

          if (!roomDocSnap.exists()) {
            await setDoc(roomDocRef, {
              userId: userId,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
            });
          }

          const userMessagesRef = collection(roomDocRef, "messages");
          const q = query(userMessagesRef, orderBy("createdAt"));

          const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessages = snapshot.docs.map((doc) => doc.data() as Message);
            setMessages(newMessages);
          });

          return () => {
            unsubscribe();
          };
        } catch (error) {
          console.error("Error fetching messages: ", error);
        }
      };

      fetchMessages();
    }
  }, [roomId, userId]);

  useEffect(() => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollTo({
        top: scrollDiv.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, inputMessage]);

  const sendMessage = async () => {
    if (!inputMessage.trim() || !roomId || !userId || !characterProfile) {
      return;
    }

    const messageData = {
      text: inputMessage,
      sender: "user",
      createdAt: serverTimestamp(),
    };

    try {
      const roomDocRef = doc(db, "rooms", roomId);
      const userMessagesRef = collection(roomDocRef, "messages");
      await addDoc(userMessagesRef, messageData);

      setInputMessage("");
      setIsLoading(true);

      console.log("Sending message to OpenAI with profile:");
      console.log(characterProfile); // プロフィールをログに出力
      console.log("User message:", inputMessage); // ユーザーの入力をログに出力

      const gpt3Response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: characterProfile },
          { role: "user", content: inputMessage },
        ],
      });

      setIsLoading(false);

      const botResponse = gpt3Response.choices[0]?.message?.content || "";
      console.log("Bot response:", botResponse); // Bot の応答をログに出力

      await addDoc(userMessagesRef, {
        text: botResponse,
        sender: "bot",
        createdAt: serverTimestamp(),
      });

      if (endDiv.current) {
        endDiv.current.scrollIntoView();
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    }
  };

  return (
    <Grid container component={Paper} style={{ height: "80vh", overflow: "hidden" }}>
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
          <Typography variant="h6">{characterJapaneseName}とのチャット</Typography>
          <Typography variant="body2" color="textSecondary">
            関係性: {relationship || "未設定"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            キャラクター名: {characterName || "不明"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            漫画タイトル: {mangaTitle || "不明"}
          </Typography>
        </Box>

        <Box
          ref={scrollDiv}
          style={{
            flexGrow: 1,
            overflowY: "auto",
            padding: "10px",
            height: "calc(100vh - 330px)", // Adjust height to make it scrollable
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              style={{
                display: "flex",
                justifyContent: message.sender === "user" ? "flex-end" : "flex-start",
                marginBottom: "10px",
              }}
            >
              <Typography
                style={{
                  backgroundColor: message.sender === "user" ? "#cfe9ff" : "#f0f0f0",
                  padding: "10px",
                  borderRadius: "10px",
                  maxWidth: "60%",
                }}
              >
                {message.text}
              </Typography>
            </Box>
          ))}
          {isLoading && <Typography>Loading...</Typography>}
          <div ref={endDiv} />
        </Box>

        <Box style={{ display: "flex", padding: "10px", borderTop: "1px solid #ddd", alignItems: "center" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="メッセージを入力してください..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          <IconButton color="primary" onClick={sendMessage}>
            <SendIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Chat;
